import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

import Schlafgut from "../../images/schlafgut.jpeg";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Datenschutzerklärung - Babyschlaf-Protokoll APP" />
      <h1>Datenschutzerklärung Babyschlaf-Protokoll APP</h1>
      <h2>Privacy Policy</h2>
      <p>Aktualisiert 17.02.2023</p>
      <p>
        Diese Datenschutzrichtlinie beschreibt die Richtlinien und Verfahren.
        Durch die Nutzung des Dienstes stimmst du den in diesem Dokument
        enthaltenen Informationen zu.
      </p>
      <h3>Persönliche Daten</h3>
      <p>
        Die APP erhebt keine weiteren personenbezogenen Daten außer dem Namen
        des Kindes und dessen Schlaf- und Stillverhalten. Es ist optional
        möglich seine E-Mail Adresse zu hintlegen. Diese Information wird weder
        ausgewertet noch weitergegeben und dient nur zur Sicherstellung der
        Datenzugehörigkeit.
      </p>
      <h3>Nutzungsdaten</h3>
      <p>Die APP sammelt keine Nutzungsdaten.</p>
      <h3>Verwendung deiner personenbezogenen Daten</h3>
      <p>
        Die Daten werden nur auf dein Ersuchen hin verarbeitet und
        weitergeleitet.
      </p>
      <h3>Aufbewahrung deiner personenbezogenen Daten</h3>
      <p>
        Die APP speichert die Daten bis du die Abmelde-Funktion nutzt. Ein
        Backup wird auf dem Schlafgut-Server vorgehalten.
      </p>
      <h3>Offenlegung deiner personenbezogenen Daten</h3>
      <p>Wird in keinem Fall vorgenommen.</p>
      <h3>Datensicherheit</h3>
      <p>Die Übermittlung der Daten auf den Server erfolgt via SSL (HTTPS).</p>
      <h3>Datenschutz für Kinder</h3>
      <p>Der Service richtet sich nicht an Personen unter 13 Jahren.</p>
      <h3>Änderungen dieser Datenschutzrichtlinie</h3>
      <p>
        Ich kann diese Datenschutzrichtlinie bei Bedarf aktualisieren. Ich
        werden dich über alle Änderungen informieren, indem ich die neue
        Datenschutzrichtlinie auf dieser Seite veröffentliche. Änderungen dieser
        Datenschutzrichtlinie werden wirksam, wenn sie auf dieser Seite
        veröffentlicht werden.
      </p>
      <h2>Die App (iOS, Android)</h2>
      <div className="row">
        <div className="col-sm-2" style={{ padding: 5 }}>
          <img
            src="https://michaelpalmer.de/sandy/app/babyschlaf-protokoll-app-logo-512.png"
            style={{
              width: 50,
              margin: 10,
            }}
            className="imagebordered"
            width="50"
            alt="Schlafgut Schlafberatung Schlafprotokoll"
          />
        </div>
        <div className="col-sm-3" style={{ padding: 5 }}>
          <a
            href="https://play.google.com/store/apps/details?id=de.schlafgut_schlafberatung"
            rel="noreferrer"
            target="_blank"
            title="Schlafgut Schlafprotokoll Google Play Store Android App"
          >
            Google Play Store
            <br />
            <img
              src="https://michaelpalmer.de/sandy/app/store_android.png"
              height="40"
              alt="Google Play Store"
              border="0"
            />
          </a>
        </div>
        <div className="col-sm-3" style={{ padding: 5 }}>
          <a
            href="https://apps.apple.com/app/schlafgut-schlafprotokoll/id1605835781"
            rel="noreferrer"
            target="_blank"
            title="Schlafgut Schlafprotokoll Apple App Store iPhone App"
          >
            Apple APP Store
            <br />
            <img
              src="https://michaelpalmer.de/sandy/app/store_iphone.png"
              height="40"
              alt="Apple App Store iPhone App"
              border="0"
            />
          </a>
        </div>
      </div>
      <br />
      <p>
        Die APP für das persönliche Schlafprotokoll deines Babys oder
        Kleinkindes.
        <br />
        Weitere Infos dazu unter{" "}
        <a href="/apps/babyschlaf-protokoll-app" target="_self">
          palmomedia.de/apps/babyschlaf-protokoll-app
        </a>
        .
      </p>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
